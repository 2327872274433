import React from "react";
import compose from "recompose/compose";
import withProductsByOptions from "theme/modules/ProductView/withProductsByOptions";
import { graphql } from "react-apollo";
import SizeAvailableQuery from "./SizeAvailableQuery.gql";
import classNames from "classnames";

const SizeAvailable = ({ loadingSize, sizes, availabilities }) => {
  let sizeAvailables = "";
  if (loadingSize) {
    return (
      <div className="size-availables size-availables--skeleton">
        <span className="size" />
        <span className="size" />
        <span className="size" />
        <span className="size" />
        <span className="size" />
        <span className="size" />
        <span className="size" />
        <span className="size" />
        <span className="size" />
      </div>
    );
  }
  if (sizes.length === 0) {
    return "";
  }

  let sizeAvailableStock = [];
  if (availabilities) {
    availabilities.map((availability) => {
      sizeAvailableStock.push(availability.configuration[0].value);
      return true;
    });
  }

  sizeAvailables = sizes.map((size) => {
    return size.values.map((sizeOption) => {
      return (
        <span
          key={sizeOption.value}
          className={classNames("size", {
            "size--out-stock":
              sizeAvailableStock.indexOf(sizeOption.value) === -1,
          })}
        >
          {sizeOption.label}
        </span>
      );
    });
  });
  return <div className="size-availables">{sizeAvailables}</div>;
};

export default compose(
  graphql(SizeAvailableQuery, {
    options: (props) => {
      return {
        variables: {
          sku: props.sku,
        },
      };
    },
    props: ({ data }) => {
      return {
        loadingSize: data.loading,
        sizes:
          (!data.loadingSize && data.product && data.product.options) || [],
        availabilities:
          (!data.loadingSize && data.product && data.product.configurations) ||
          [],
      };
    },
  }),
  withProductsByOptions()
)(SizeAvailable);
