import React from "react";
import H4 from "theme/components/atoms/Typography/Heading/H4";
import LabelledIcon from "theme/components/molecules/LabelledIcon";
import ResizedImage from "theme/components/atoms/ResizedImage";
import IsDesktop from "theme/utils/isDesktop";
import { ButtonLink } from "theme/components/atoms/Typography/Link";
import { makeAbsoluteUrl } from "theme/modules/Core";
import classNames from "classnames";

const ImageQuote = ({
  title,
  text,
  subtitle,
  ctaLabel,
  ctaLink,
  headingLevel = "h3",
  font_size,
  withBackgroundColor,
}) => {
  return (
    <div
      className={classNames("imagequote__content", {
        "imagequote__content--with-bg-color": withBackgroundColor,
      })}
    >
      {!withBackgroundColor && (
        <IsDesktop>
          <ResizedImage
            src="/media/collegien/quote.png"
            format="small"
            alt="quote"
          />
        </IsDesktop>
      )}
      <div className="box-content">
        <H4 as={headingLevel}>{title}</H4>
        <span className="subtitle">{subtitle}</span>
        <div
          className="text"
          style={{
            fontSize: font_size ? font_size : "4rem",
          }}
        >
          {text}
        </div>
        {ctaLabel && (
          <ButtonLink
            buttonAppearance="thirdly"
            to={makeAbsoluteUrl(ctaLink ?? "/")}
          >
            <LabelledIcon icon="chevron-right" size="tiny" inverted>
              {ctaLabel}
            </LabelledIcon>
          </ButtonLink>
        )}
      </div>
    </div>
  );
};

export default ImageQuote;
