import React from "react";
import Image from "./Image";
import withGenderFilter from "theme/components/helpers/withGenderFilter";

const ThreePush = ({ data, loading, currentGenderLabel, genderFilterName }) => {
  if (loading || !data) {
    return null;
  }
  const { images } = data;

  return (
    <section className="three-images">
      {images.map((image, key) => (
        <Image
          key={key}
          image={image}
          genderFilterName={genderFilterName}
          currentGenderLabel={currentGenderLabel}
        />
      ))}
    </section>
  );
};

export default withGenderFilter()(ThreePush);
