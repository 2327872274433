import React from "react";
import PropTypes from "prop-types";
import Link from "theme/components/atoms/Typography/Link";
import ResizedImage from "theme/components/atoms/ResizedImage";
import generateLinkWidget from "theme/components/helpers/generateLinkWidget";
import withGenderFilter from "theme/components/helpers/withGenderFilter";

const Image = ({
  image,
  currentGenderLabel,
  genderFilterName,
  isCategoryLink,
  link,
}) => {
  if (image.type === "video") {
    return (
      <div className="image-text__video-container">
        <iframe
          className="image-text__video"
          src={`https://www.youtube.com/embed/${image.url}`}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="video"
        />
      </div>
    );
  }
  return (
    <Link
      to={generateLinkWidget(
        link,
        isCategoryLink,
        currentGenderLabel,
        genderFilterName
      )}
    >
      <ResizedImage
        src={image.url}
        alt={image.alt}
        srcset={image.srcset}
        format="square"
        cover
      />
    </Link>
  );
};

Image.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  primary: PropTypes.bool,
};

export default withGenderFilter()(Image);
