import React, { Component } from "react";
import {
  withState,
  compose,
  withProps,
  branch,
  renderNothing,
} from "recompose";
import IsDesktop from "theme/utils/isDesktop";
import Mobile from "./Mobile";
import Desktop from "./Desktop";
import { withRouter } from "react-router";
import PropTypes from "prop-types";

class SimilarColor extends Component {
  render() {
    return (
      <div className="similar-color">
        <IsDesktop>
          {(IsDesktop) => {
            if (IsDesktop) {
              return <Desktop props={this.props} />;
            }
            return <Mobile props={this.props} />;
          }}
        </IsDesktop>
      </div>
    );
  }
}
SimilarColor.propTypes = {
  sku: PropTypes.string,
  products: PropTypes.array,
  loading: PropTypes.bool,
};

export default compose(
  withRouter,
  withState("currentColorName", "setCurrentColorName", (props) =>
    props.currentProductColorName ? props.currentProductColorName : false
  ),
  withState("currentColorHexa", "setCurrentColorHexa", (props) =>
    props.currentProductColorHexa ? props.currentProductColorHexa : false
  ),
  withState("displayThumbnails", "setDisplayThumbnails", false),
  withState("displayModal", "setDisplayModal", false),
  withProps((props) => {
    if (props.products.length === 0) {
      return { productList: {} };
    } else {
      return {
        productList: props.products.filter((product) => {
          if (!product || !product.path) {
            return false;
          }
          return true;
        }),
      };
    }
  }),
  withProps((props) => {
    if (props.productList === 0) {
      return {
        productList: {},
        displayInDropdown: false,
        forceDisplay: props.forceDisplay ? props.forceDisplayInDropdown : false,
        maxColorDisplay: props.maxColorDisplay ? props.maxColorDisplay : 10,
      };
    } else {
      let maxColorDisplay = props.maxColorDisplay ? props.maxColorDisplay : 10;
      let productList = props.products.filter((product) => {
        if (!product || !product.path) {
          return false;
        }
        return true;
      });
      return {
        productList,
        maxColorDisplay,
        forceDisplay: props.forceDisplay ? props.forceDisplay : false,
        displayInDropdown: productList.length <= maxColorDisplay ? false : true,
      };
    }
  }),
  branch(
    (props) => props.productList.length === 0,
    renderNothing,
    (BaseComponent) => BaseComponent
  )
)(SimilarColor);
