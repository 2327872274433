import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { ProductPrice } from "theme/components/atoms/Typography/Price";
import safeHtml from "web/core/utils/safeHtml";
import classNames from "classnames";

const ProductOverview = ({ name, prices, sizes }) => {
  const nameLines = name.split(/ - (.+)/);

  return (
    <div className="product-overview">
      <div className="product-overview__name" itemProp="name">
        {nameLines && nameLines.length > 0 ? (
          <>
            {nameLines[0]}
            {nameLines.length > 1 ? (
              <span className="product-overview__name__subline">
                {nameLines[1].trim()}
              </span>
            ) : (
              ""
            )}
          </>
        ) : (
          <span dangerouslySetInnerHTML={{ __html: safeHtml(name) }} />
        )}
      </div>
      <div
        className={classNames("product-overview__price", {
          "product-overview__price--hidden":
            !prices || !prices.finalPrice?.priceInclTax?.value?.amount,
        })}
        itemProp="offers"
        itemScope
        itemType="http://schema.org/Offer"
      >
        <FormattedMessage
          id="modules.ProductView.ProductItem.Overview.pricePrefix"
          defaultMessage="From {price}"
          values={{ price: <ProductPrice prices={prices} /> }}
        />
      </div>
      {sizes && sizes.length > 0 && (
        <div className="product-overview__sizes">
          {sizes.map((size, index) => (
            <span key={index} className="product-overview__sizes__size">
              {size}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

ProductOverview.propTypes = {
  name: PropTypes.string,
  prices: ProductPrice.propTypes.prices,
  sizes: PropTypes.array,
};

export default ProductOverview;
