import React from "react";
import { injectIntl } from "react-intl";
import ProductItem from "theme/modules/ProductView/ProductItem";
import Swipe from "theme/components/organisms/Swipe";
import { H4 } from "theme/components/atoms/Typography/Heading";
import { RoundIconButton } from "theme/components/atoms/Button";
import { ButtonLink } from "theme/components/atoms/Typography/Link";
import LabelledIcon from "theme/components/molecules/LabelledIcon";
import messages from "./messages";
import { makeAbsoluteUrl } from "theme/modules/Core";

function ProductSliderMobile({
  headingLevel,
  title,
  products,
  ctaLink,
  ctaLabel,
  intl,
}) {
  const [index, setIndex] = React.useState(0);
  const previous = products[index - 1];
  const current = products[index];
  const next = products[index + 1];
  const productPercent = 100 / products.length;

  return (
    <div className="product-slider">
      <div className="product-slider__title">
        <H4 as={headingLevel}>{title}</H4>
      </div>
      <Swipe
        current={
          <ProductItem
            key={current.sku}
            {...current}
            products={products}
            product={current}
            index={index}
          />
        }
        previous={
          previous && (
            <ProductItem
              key={previous.sku}
              product={previous}
              {...previous}
              products={products}
              index={index - 1}
            />
          )
        }
        next={
          next && (
            <ProductItem
              key={next.sku}
              product={next}
              {...next}
              products={products}
              index={index + 1}
            />
          )
        }
        onNext={next ? () => setIndex(index + 1) : null}
        onPrevious={previous ? () => setIndex(index - 1) : null}
      />
      <div className="product-slider__prevnext">
        <div className="product-slider__prevnext__before">
          <RoundIconButton
            state={index <= 0 ? "disabled" : null}
            onDisableClick={() => {}}
            onClick={() => setIndex(index - 1)}
            icon="chevron-left"
            iconSize="tiny"
            title={intl.formatMessage(messages.previous)}
          />
        </div>
        <div className="product-slider__prevnext__position">
          <div
            className="product-slider__prevnext__position__current"
            style={{
              width: `${productPercent}%`,
              left: `${productPercent * index}%`,
            }}
          />
        </div>
        <div className="product-slider__prevnext__after">
          <RoundIconButton
            state={index >= products.length - 1 ? "disabled" : null}
            onDisableClick={() => {}}
            onClick={() => setIndex(index + 1)}
            icon="chevron-right"
            iconSize="tiny"
            title={intl.formatMessage(messages.next)}
          />
        </div>
      </div>
      {ctaLink && ctaLabel && (
        <div className="product-slider__cta">
          <ButtonLink buttonAppearance="thirdly" to={makeAbsoluteUrl(ctaLink)}>
            <LabelledIcon icon="chevron-right" inverted size="tiny">
              {ctaLabel}
            </LabelledIcon>
          </ButtonLink>
        </div>
      )}
    </div>
  );
}

export default injectIntl(ProductSliderMobile);
