import React from "react";
import PropTypes from "prop-types";
import ResizedImage from "theme/components/atoms/ResizedImage";
import { makeAbsoluteUrl } from "theme/modules/Core";

const Image = ({ image, ctaLink, format }) => {
  return (
    <a
      href={makeAbsoluteUrl(ctaLink ?? "/")}
      className={`imagequote__image--${format}`}
    >
      <ResizedImage
        src={image.url}
        srcset={image.srcset}
        alt={image.alt}
        format="square"
        cover
      />
    </a>
  );
};

Image.propTypes = {
  image: PropTypes.shape({
    media: PropTypes.string,
    alt: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  primary: PropTypes.bool,
};

export default Image;
