import React from "react";
import classNames from "classnames";
import Link from "theme/components/atoms/Typography/Link";

const Thumbnail = ({
  setPreviewContent,
  setCurrentColorName,
  product,
  currentProductColorName,
  currentProductSku,
  previewContent,
  IsDesktop,
  onClick,
}) => {
  return (
    <div>
      <Link to={product.path} onClick={onClick}>
        {IsDesktop && (
          <span className="similar-color__thumbnail__name">
            {product.collegien_color_name}
          </span>
        )}
        <span
          className={classNames("similar-color__thumbnail", {
            "similar-color__thumbnail--current":
              currentProductSku === product.sku && !previewContent.image,
            // "similar-color__thumbnail--new-color": product.isNewColor,
          })}
          onMouseEnter={() => {
            setPreviewContent &&
              setPreviewContent(
                Object.assign(previewContent, {
                  image: product.imageUrl,
                  name: product.name,
                  sku: product.sku,
                })
              );
            setCurrentColorName(product.collegien_color_name);
          }}
          onMouseLeave={() => {
            setPreviewContent &&
              setPreviewContent(
                Object.assign(previewContent, {
                  image: false,
                  name: false,
                  sku: false,
                })
              );
            setCurrentColorName(currentProductColorName);
          }}
        >
          <span
            className="similar-color__thumbnail__color"
            style={{
              backgroundColor: `${product.collegien_color_hexa}`,
            }}
          />
        </span>
      </Link>
    </div>
  );
};

export default Thumbnail;
