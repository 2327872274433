import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import Icon from "theme/components/atoms/Icon";
import Thumbnail from "./Thumbnail";

const Desktop = ({ props }) => {
  const {
    productList,
    currentColorName,
    setCurrentColorName,
    currentProductColorName,
    previewContent,
    setPreviewContent,
    sku,
    path,
    displayThumbnails,
    setDisplayThumbnails,
    displayInDropdown,
    forceDisplay,
    maxColorDisplay,
    displayType,
  } = props;
  return (
    <Fragment>
      <div className="form-item__label">
        <small>
          <FormattedMessage
            id="components.organism.SimilarColor.Title"
            defaultMessage="Color"
          />
        </small>
        <span className="similar-color__color-name">
          {currentColorName && currentColorName}
        </span>
      </div>
      <div
        className={classNames("similar-color__thumbnails", {
          "similar-color__thumbnails--absolute":
            displayInDropdown && !forceDisplay && displayThumbnails,
        })}
      >
        {productList.map((product, index) => {
          if (
            (forceDisplay || (displayInDropdown && !displayThumbnails)) &&
            index > maxColorDisplay
          ) {
            return false;
          }
          return (
            <Thumbnail
              key={index}
              currentProductSku={sku}
              previewContent={previewContent}
              setPreviewContent={setPreviewContent}
              currentProductColorName={currentProductColorName}
              setCurrentColorName={setCurrentColorName}
              product={product}
              displayType={displayType}
              onClick={() => {
                setDisplayThumbnails(false);
              }}
              IsDesktop={false}
            />
          );
        })}
      </div>
      {displayInDropdown && (
        <span
          className={classNames("similar-color__color-count", {
            "similar-color__color-count--open": displayThumbnails,
          })}
          onClick={() => {
            if (forceDisplay) {
              window.location.href = path;
            } else {
              setDisplayThumbnails(!displayThumbnails);
            }
          }}
        >
          <FormattedMessage
            id="components.organism.SimilarColor.moreColor"
            defaultMessage="+ {colorCount} colors"
            values={{
              colorCount: productList.length - maxColorDisplay,
            }}
          />
          <Icon icon="chevron" size="tiny" title="open" />
        </span>
      )}
    </Fragment>
  );
};

export default Desktop;
