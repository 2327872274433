const getProductsFor = (id, value, configurations) =>
  configurations
    .filter(({ configuration }) =>
      configuration.some(
        (set) => set.attribute_id === id && set.value === value
      )
    )
    .map(({ product, prices }) => {
      return { ...product, ...prices };
    });

const addAvailableProductsToOptions = (options, configurations) => {
  return options.map(({ attribute, values, ...option }) => ({
    ...option,
    values: values.map(({ label, value, swatch }) => ({
      label,
      value,
      swatch,
      availableProducts: getProductsFor(attribute.id, value, configurations),
    })),
    attribute,
  }));
};

export default addAvailableProductsToOptions;
