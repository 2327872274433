import React from "react";
import Link from "theme/components/atoms/Typography/Link";
import LabelledIcon from "theme/components/molecules/LabelledIcon";
import ResizedImage from "theme/components/atoms/ResizedImage";
import generateLinkWidget from "theme/components/helpers/generateLinkWidget";
import withGenderFilter from "theme/components/helpers/withGenderFilter";

const Image = ({
  image,
  display_type,
  hover,
  hoverImage,
  genderFilterName,
  currentGenderLabel,
  isCategoryLink,
  ctaLabel,
  ctaLink,
  label,
}) => {
  return (
    <div className="push__image">
      <Link
        to={generateLinkWidget(
          ctaLink,
          isCategoryLink,
          currentGenderLabel,
          genderFilterName
        )}
      >
        <ResizedImage
          src={image.url}
          srcset={image.srcset}
          alt={image.alt}
          format={display_type === "full" ? "hero" : "push"}
          srcsetFormat={{ medium: "heroTablet", small: "heroMobile" }}
          cover
        />
        {hoverImage && (
          <div
            className={
              hover
                ? "push__image__hover push__image__hover--show"
                : "push__image__hover"
            }
          >
            <ResizedImage
              src={hoverImage.url}
              srcset={hoverImage.srcset}
              alt={hoverImage.alt}
              format={display_type === "full" ? "hero" : "push"}
              srcsetFormat={{ medium: "heroTablet", small: "heroMobile" }}
              cover
            />
          </div>
        )}
        <div className="push__image__content">
          <h4>{label}</h4>
          <span className="link button button--ghost button--default">
            <LabelledIcon icon="chevron-right" inverted size="tiny">
              {ctaLabel}
            </LabelledIcon>
          </span>
        </div>
      </Link>
    </div>
  );
};

Image.defaultProps = {
  primary: false,
};

export default withGenderFilter()(Image);
