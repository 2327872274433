import React, { Component } from "react";
import PropTypes from "prop-types";
import Measure from "theme/utils/Measure";

const startTransform = (measure, delta, position) => {
  return position * 100;
};
const middleTransform = (measure, delta, position) => {
  return (position - delta.x / measure.width) * 100;
};
const endTransform = (measure, delta, position) => {
  return (delta.end + position) * 100;
};

const computeSlideOfArrival = (duration, state, props) => {
  return duration < 50 || state.delta.x === 0
    ? 0
    : duration > 250
    ? Math.abs(state.delta.x) > 100
      ? state.delta.x < 0
        ? props.onPrevious
          ? 1
          : 0
        : props.onNext
        ? -1
        : 0
      : 0
    : state.delta.x < 0
    ? props.onPrevious
      ? 1
      : 0
    : props.onNext
    ? -1
    : 0;
};

class Swipe extends Component {
  constructor() {
    super();
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.onEnd = this.onEnd.bind(this);
    this.state = {
      delta: null,
    };
  }

  componentDidMount() {
    if (this.slide) {
      this.container.addEventListener("touchstart", this.onTouchStart, false);
    }
  }

  componentWillUnmount() {
    this.stop();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.stop();
      this.container.addEventListener("touchstart", this.onTouchStart, false);
      this.setState({
        delta: null,
      });
    }
  }

  onTouchStart(event) {
    this.stop();

    const touch = event.touches[0];

    this.start = {
      x: touch.pageX,
      y: touch.pageY,
      time: +new Date(),
    };

    this.isScrollingHorizontally = undefined;

    requestAnimationFrame(() => {
      this.setState({
        delta: {
          hasEnded: false,
          end: undefined,
          x: 0,
          y: 0,
        },
      });
      this.container.addEventListener("touchmove", this.onTouchMove, false);
      this.container.addEventListener("touchend", this.onTouchEnd, false);
      this.container.removeEventListener(
        "touchstart",
        this.onTouchStart,
        false
      );
    });
  }

  onTouchMove(event) {
    // Do not swipe if the user is scrolling/pinching/etc.
    if (event.touches.length > 1 || (event.scale && event.scale !== 1)) {
      return;
    }

    const touch = event.touches[0];
    const delta = {
      hasEnded: false,
      end: undefined,
      x: this.start.x - touch.pageX,
      y: this.start.y - touch.pageY,
    };

    if (typeof isScrollingHorizontally === "undefined") {
      this.isScrollingHorizontally = Math.abs(delta.x) > Math.abs(delta.y);
    }

    if (this.isScrollingHorizontally) {
      event.preventDefault();

      requestAnimationFrame(() => {
        this.setState({
          delta: delta,
        });
      });
    }
  }

  onTouchEnd(event) {
    if (this.slide) {
      requestAnimationFrame(() => {
        this.setState((state) => {
          const duration = this.start ? new Date() - this.start.time : 0;
          const end = computeSlideOfArrival(duration, state, this.props);

          return {
            delta: {
              hasEnded: true,
              end: end,
              x: 0,
              y: 0,
            },
          };
        });
      });

      this.slide.addEventListener("transitionend", this.onEnd);
    }
  }

  onEnd() {
    if (this.state.delta.end !== 0) {
      const callback =
        this.state.delta.end === -1 ? this.props.onNext : this.props.onPrevious;
      typeof callback === "function" && callback();
    }
    this.container.addEventListener("touchstart", this.onTouchStart, false);
    this.stop();
  }

  stop() {
    if (this.start) {
      this.container.removeEventListener("touchmove", this.onTouchMove, false);
      this.container.removeEventListener("touchend", this.onTouchEnd, false);
      this.slide.removeEventListener("transitionend", this.onEnd);
      this.start = null;
    }
  }

  render() {
    return (
      <Measure>
        {({ setRef, measure }) => {
          let transform = null;
          if (this.state.delta) {
            if (this.state.delta.hasEnded) {
              transform = endTransform;
            } else {
              transform = middleTransform;
            }
          } else {
            transform = startTransform;
          }

          const transition =
            this.state.delta && this.state.delta.hasEnded
              ? "transform 0.3s ease"
              : "";
          return (
            <div
              className="swipe"
              ref={(ref) => {
                this.container = ref;
                setRef(ref);
              }}
              onClick={this.props.onClick}
            >
              {this.props.previous && (
                <div
                  className="swipe__previous"
                  key={this.props.previous.key}
                  style={{
                    transition: transition,
                    transform: `translateX(${transform(
                      measure,
                      this.state.delta,
                      -1
                    )}%)`,
                  }}
                >
                  {this.props.previous}
                </div>
              )}
              <div
                className="swipe__current"
                key={this.props.current.key}
                ref={(ref) => {
                  this.slide = ref;
                }}
                style={{
                  transition: transition,
                  transform: `translateX(${transform(
                    measure,
                    this.state.delta,
                    0
                  )}%)`,
                }}
              >
                {this.props.current}
              </div>
              {this.props.next && (
                <div
                  className="swipe__next"
                  key={this.props.next.key}
                  style={{
                    transition: transition,
                    transform: `translateX(${transform(
                      measure,
                      this.state.delta,
                      1
                    )}%)`,
                  }}
                >
                  {this.props.next}
                </div>
              )}
            </div>
          );
        }}
      </Measure>
    );
  }
}

Swipe.propTypes = {
  previous: PropTypes.node,
  current: PropTypes.node.isRequired,
  next: PropTypes.node,
};

export default Swipe;
