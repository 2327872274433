import withProps from "recompose/withProps";
import getSelectableOptions from "./utils/getSelectableOptions";

const withProductsByOptions = () =>
  withProps(({ product }) => ({
    product: {
      ...product,
      options: getSelectableOptions(product?.options, product?.configurations),
      configurations: product?.configurations || [],
    },
  }));

export default withProductsByOptions;
