import { defineMessages } from "react-intl";

export default defineMessages({
  previous: {
    id: "organisms.ProductSlider.previous",
    defaultMessage: "previous",
  },
  next: {
    id: "organisms.ProductSlider.next",
    defaultMessage: "next",
  },
});
