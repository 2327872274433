import { compose, withState } from "recompose";
import withGenderFilter from "theme/components/helpers/withGenderFilter";
import { withHideOnErrorBoundary } from "theme/components/helpers/ErrorBoundary";

export default () =>
  compose(
    withHideOnErrorBoundary,
    withGenderFilter(),
    withState("hover", "setHover", (props) =>
      props.data?.mediaHover === true ? true : false
    ),
    withState("hoverTitle", "setHoverTitle", false)
  );
