import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ProductOverview from "theme/modules/ProductView/Overview";
import ResizedImage from "theme/components/atoms/ResizedImage";
import TrackingLink from "theme/components/atoms/Typography/Link/TrackingLink";
import SimilarColor from "theme/components/organisms/SimilarColor";
import SizeAvailable from "theme/components/organisms/SizeAvailable";
import IsDesktop from "theme/utils/isDesktop";
import { withState, compose, lifecycle, withProps, branch } from "recompose";
import Stickers from "theme/components/organisms/Stickers/Stickers";
import withGenderFilter from "theme/components/helpers/withGenderFilter";
import WysiwygV2 from "theme/modules/WysiwygV2";
import { graphql } from "react-apollo";
import ProductItemHoverQuery from "./ProductItemHoverQuery.gql";
const PushContent = ({ wysiwyg }) => {
  if (wysiwyg) {
    return wysiwyg && <WysiwygV2 content={wysiwyg.contentWysiwyg} />;
  }
  return null;
};
const ProductItem = (props) => {
  if (props.push_redirect) {
    return (
      <div
        itemProp={props.itemProp}
        itemScope
        itemType="http://schema.org/Product"
        className={classNames("product-list__item product-push product-item", {
          "product-item--grid": props.isGrid,
        })}
      >
        <PushContent wysiwyg={props.push_redirect} />
      </div>
    );
  } else {
    return (
      <div
        onMouseEnter={() => {
          if (!props.hover) {
            props.setHover(true);
          }
        }}
        itemProp={props.itemProp}
        itemScope
        itemType="http://schema.org/Product"
        className={
          classNames("product-list__item product-item", {
            "product-item--grid": props.isGrid,
          }) +
          (props.productHoverProps?.similarColor &&
          props.productHoverProps?.similarColor.length
            ? " product-item--withcolor"
            : "")
        }
      >
        <Stickers isNew={props.isNew} stickers={props.stickers} />
        <TrackingLink
          eventName="Product Clicked"
          eventPayload={{ sku: props.sku, name: props.name }}
          to={{
            pathname: props.path,
            state: props.location
              ? {
                  returnTo: `${props.location.pathname}${props.location.search}`,
                  products: props.products,
                  selected: props.index,
                  breadcrumb: props.breadcrumb,
                }
              : { breadcrumb: props.breadcrumb },
          }}
          clickType="block"
        >
          <ResizedImage
            itemProp="image"
            className="product-item__main-image"
            src={
              props.previewContent && props.previewContent.image
                ? props.previewContent.image
                : props.imageUrl
            }
            alt={props.name}
            format="smallListing"
          />
        </TrackingLink>

        <div className="product-item__overview">
          <TrackingLink
            eventName="Product Clicked"
            eventPayload={{ sku: props.sku, name: props.name }}
            to={{
              pathname: props.path,
              state: props.location
                ? {
                    returnTo: `${props.location.pathname}${props.location.search}`,
                    products: props.products,
                    selected: props.index,
                    breadcrumb: props.breadcrumb,
                  }
                : { breadcrumb: props.breadcrumb },
            }}
            clickType="block"
          >
            <ProductOverview
              sku={
                props.previewContent.sku ? props.previewContent.sku : props.sku
              }
              path={props.path}
              name={
                props.previewContent.name
                  ? props.previewContent.name
                  : props.name
              }
              prices={props.prices}
              stock={props.stock}
              products={props.products}
              breadcrumb={props.breadcrumb}
              location={props.location}
              index={props.index}
              sizes={props.sizes}
            />
            <IsDesktop>
              <>
                {props.hover &&
                  !props.productHoverProps?.loading &&
                  props.productHoverProps?.similarColor.length === 0 && (
                    <SizeAvailable
                      sku={
                        props.previewContent.sku
                          ? props.previewContent.sku
                          : props.product.sku
                      }
                    />
                  )}
              </>
            </IsDesktop>
          </TrackingLink>
          <IsDesktop>
            <>
              {props.hover
                ? props.productHoverProps?.similarColor && (
                    <SimilarColor
                      sku={props.sku}
                      path={props.path}
                      products={props.productHoverProps?.similarColor}
                      setPreviewContent={props.setPreviewContent}
                      previewContent={props.previewContent}
                      forceDisplay={true}
                      maxColorDisplay={7}
                    />
                  )
                : null}
              {props.hover &&
                !props.productHoverProps?.loading &&
                props.productHoverProps?.similarColor.length > 0 && (
                  <SizeAvailable
                    sku={
                      props.previewContent.sku
                        ? props.previewContent.sku
                        : props.product.sku
                    }
                  />
                )}
            </>
          </IsDesktop>
        </div>
        <meta itemProp="category" content={props.categoryName} />
      </div>
    );
  }
};

ProductItem.propTypes = {
  isGrid: PropTypes.bool,
  imageUrl: PropTypes.string,
  categoryName: PropTypes.string,
  ...ProductOverview.propTypes,
};

export default compose(
  withState("hover", "setHover", false),
  branch(
    (props) => props.hover,
    compose(
      graphql(ProductItemHoverQuery, {
        options: (props) => ({
          variables: {
            sku: props.sku,
          },
        }),
        props: ({ data }) => {
          return {
            productHoverProps: {
              loading: data.loading,
              collegien_color_name: data?.product?.collegien_color_name,
              collegien_color_hexa: data?.product?.collegien_color_name,
              similarColor: data?.product?.similarColor,
            },
          };
        },
      })
    ),
    compose(
      withProps((props) => {
        return {
          productHoverProps: null,
        };
      })
    )
  ),
  withState("previewContent", "setPreviewContent", {
    image: false,
    name: false,
    sku: false,
  }),
  lifecycle({
    componentDidMount() {
      this.props.setPreviewContent({
        image: false,
        name: false,
        sku: false,
      });
    },
  }),
  withGenderFilter(),
  withProps((props) => {
    return {
      imageUrl:
        props.product?.adult_image &&
        props.product?.adult_image !== "no_selection" &&
        props.isAdultGender
          ? `/media/catalog/product${props.product?.adult_image}`
          : props.imageUrl,
    };
  })
)(ProductItem);
