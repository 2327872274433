import React from "react";
import ResizedImage from "theme/components/atoms/ResizedImage";
import { Parallax } from "react-scroll-parallax";
import IsDesktop from "theme/utils/isDesktop";
import Link from "theme/components/atoms/Typography/Link";
import generateLinkWidget from "theme/components/helpers/generateLinkWidget";
import withGenderFilter from "theme/components/helpers/withGenderFilter";

const ParallaxImage = ({
  loading,
  currentGenderLabel,
  genderFilterName,
  data,
}) => {
  if (loading || !data) {
    return null;
  }
  const { isCategoryLink, link, images } = data;

  return (
    <IsDesktop>
      {(isDesktop) => {
        return (
          <Link
            buttonAppearance="ghost"
            to={generateLinkWidget(
              link,
              isCategoryLink,
              currentGenderLabel,
              genderFilterName
            )}
            className="parallax-images--link"
          >
            <section className="parallax-images">
              {isDesktop ? (
                <Parallax y={["-30", "50"]}>
                  <ResizedImage
                    src={images[1].url}
                    srcset={images[1].srcset}
                    alt={images[1].alt}
                    format={"imageParallaxSmall"}
                    srcsetFormat={{
                      medium: "imageParallaxSmall",
                      small: "imageParallaxSmallMobile",
                    }}
                    cover
                  />
                </Parallax>
              ) : (
                <ResizedImage
                  src={images[1].url}
                  srcset={images[1].srcset}
                  alt={images[1].alt}
                  format={"imageParallaxSmall"}
                  srcsetFormat={{
                    medium: "imageParallaxSmall",
                    small: "imageParallaxSmallMobile",
                  }}
                  cover
                />
              )}
              <ResizedImage
                src={images[0].url}
                srcset={images[0].srcset}
                alt={images[0].alt}
                format={"imageParallaxLarge"}
                srcsetFormat={{
                  medium: "imageParallaxLarge",
                  small: "imageParallaxLargeMobile",
                }}
                cover
              />
              {isDesktop ? (
                <Parallax y={["50", "-30"]}>
                  <ResizedImage
                    src={images[2].url}
                    srcset={images[2].srcset}
                    alt={images[2].title}
                    format={"imageParallaxSmall"}
                    srcsetFormat={{
                      medium: "imageParallaxSmall",
                      small: "imageParallaxSmallMobile",
                    }}
                    cover
                  />
                </Parallax>
              ) : (
                <ResizedImage
                  src={images[2].url}
                  srcset={images[2].srcset}
                  alt={images[2].alt}
                  format={"imageParallaxSmall"}
                  srcsetFormat={{
                    medium: "imageParallaxSmall",
                    small: "imageParallaxSmallMobile",
                  }}
                  cover
                />
              )}
            </section>
          </Link>
        );
      }}
    </IsDesktop>
  );
};

export default withGenderFilter()(ParallaxImage);
