import React from "react";

const TitleText = ({ loading, data }) => {
  if (loading) {
    return (
      <section className="title-text loading-skeleton">
        <span className="title-text__title"></span>
        <p className="title-text__text"></p>
      </section>
    );
  }

  if (!data) {
    return null;
  }
  const { title, text } = data;
  return (
    <section className="title-text loading-skeleton">
      <span className="title-text__title">{title}</span>
      <p className="title-text__text">{text}</p>
    </section>
  );
};

export default TitleText;
