import React from "react";
import Breakpoints from "theme/components/atoms/Breakpoints";
import BackgroundImage from "theme/components/atoms/Image/BackgroundImage";
import Link from "theme/components/atoms/Typography/Link";
import MediaQuery from "theme/components/helpers/MediaQuery";
import useAriaAttributes from "theme/components/helpers/useAriaAttributes";
import useDataAttributes from "theme/components/helpers/useDataAttributes";
import LabelledIcon from "theme/components/molecules/LabelledIcon/LabelledIcon";
import PropTypes from "prop-types";

/** @type {React.FunctionComponent<CarouselSlideProps>} */
const CarouselSlide = ({
  imageSrc,
  mobileImage,
  priority,
  title,
  linkTitle,
  to,
  imageSizes,
  children,
  ...otherProps
}) => {
  const dataAttributes = useDataAttributes(otherProps);
  const ariaAttributes = useAriaAttributes(otherProps);
  return (
    <div className="carousel__slide" {...dataAttributes} {...ariaAttributes}>
      <CarouselSlideImage
        desktopImage={imageSrc}
        mobileImage={mobileImage}
        priority={priority}
        sizes={imageSizes}
      />
      <div className="container">
        <div className="carousel__content">
          {title && to && linkTitle ? (
            <>
              <div className="carousel__title">{title}</div>
              <div className="carousel__link">
                <Link to={to}>
                  <LabelledIcon icon="chevron-right" iconAppearance="round">
                    {linkTitle}
                  </LabelledIcon>
                </Link>
              </div>
            </>
          ) : (
            children
          )}
        </div>
      </div>
    </div>
  );
};

/* eslint-disable react/forbid-foreign-prop-types */
/** @typedef {PropTypes.InferProps<typeof propTypes>} CarouselSlideProps */
const propTypes = {
  imageSrc: PropTypes.string,
  mobileImage: PropTypes.string,
  linkTitle: PropTypes.string,
  title: PropTypes.node,
  to: Link.propTypes.to,
  // priority: Image.propTypes.priority,
  // imageSizes: Image.propTypes.sizes,
};

CarouselSlide.propTypes = propTypes;
/* eslint-enable react/forbid-foreign-prop-types */

export default CarouselSlide;

/** @type {React.FunctionComponent<CarouselSlideImageProps>} */
const CarouselSlideImage = ({ desktopImage, mobileImage, priority, sizes }) => {
  if (!desktopImage && !mobileImage) {
    return null;
  } else if (!mobileImage) {
    return (
      <BackgroundImage
        src={desktopImage}
        alt=""
        format="carousel"
        backdrop="dark"
        priority={priority}
        sizes={sizes}
      />
    );
  }

  return (
    <>
      <MediaQuery minWidth={Breakpoints.desktop}>
        <BackgroundImage
          src={desktopImage}
          alt=""
          format="carousel"
          backdrop="dark"
          priority={priority}
          sizes={sizes}
        />
      </MediaQuery>
      <MediaQuery maxWidth={Breakpoints.desktop - 1}>
        <BackgroundImage
          src={mobileImage}
          alt=""
          format="carousel"
          backdrop="dark"
          priority={priority}
          sizes={sizes}
        />
      </MediaQuery>
    </>
  );
};

/* eslint-disable react/forbid-foreign-prop-types */
/** @typedef {PropTypes.InferProps<typeof carouselSlideImagePropTypes>} CarouselSlideImageProps */
const carouselSlideImagePropTypes = {
  desktopImage: PropTypes.string,
  mobileImage: PropTypes.string,
  // priority: Image.propTypes.priority,
  // sizes: Image.propTypes.sizes,
};

CarouselSlideImage.propTypes = carouselSlideImagePropTypes;
/* eslint-enable react/forbid-foreign-prop-types */
