import React from "react";
import ProductItem from "theme/modules/ProductView/ProductItem";
import ResizedImage from "theme/components/atoms/ResizedImage";
import Link from "theme/components/atoms/Typography/Link";
import generateLinkWidget from "theme/components/helpers/generateLinkWidget";
import { withHideOnErrorBoundary } from "theme/components/helpers/ErrorBoundary";

const Theme = ({ loading, data }) => {
  if (loading) {
    return (
      <span className="widget-theme loading-skeleton">
        <span className="widget-theme__left" />
        <span className="widget-theme__right">
          <span className="widget-theme__right__item" />
          <span className="widget-theme__right__item" />
          <span className="widget-theme__right__item" />
          <span className="widget-theme__right__item" />
        </span>
      </span>
    );
  }

  if (!data) {
    return null;
  }
  const {
    link,
    mediaPosition,
    media,
    bottomLeftProduct,
    bottomRightProduct,
    topLeftProduct,
    topRightProduct,
    bottomLeftMedia,
    bottomRightMedia,
    topLeftMedia,
    topRightMedia,
  } = data;
  const topLeft = topLeftProduct?.sku ? topLeftProduct : topLeftMedia;
  const topRight = topRightProduct?.sku ? topRightProduct : topRightMedia;
  const bottomLeft = bottomLeftProduct?.sku
    ? bottomLeftProduct
    : bottomLeftMedia;
  const bottomRight = bottomRightProduct?.sku
    ? bottomRightProduct
    : bottomRightMedia;
  return (
    <span className="widget-theme">
      <span className="widget-theme__left">
        {link ? (
          <Link buttonAppearance="ghost" to={generateLinkWidget(link)}>
            <ResizedImage
              src={media.url}
              srcset={media.srcset}
              alt={media.alt}
              format="themeFullImage"
              appearance="full"
              cover
            />
          </Link>
        ) : (
          <ResizedImage
            src={media.url}
            srcset={media.srcset}
            alt={media.alt}
            format="themeFullImage"
            appearance="full"
            cover
          />
        )}
      </span>
      <span className={`widget-theme__right ${mediaPosition}`}>
        {[topLeft, topRight, bottomLeft, bottomRight].map((content, index) => {
          if (content?.sku) {
            return (
              <span
                className="widget-theme__right__item widget-theme__right__item--product"
                key={content.sku}
              >
                <ProductItem
                  {...content}
                  products={[content]}
                  product={content}
                />
              </span>
            );
          } else if (content?.srcset) {
            return (
              <span
                key={content.url}
                className="widget-theme__right__item widget-theme__right__item--media"
              >
                <ResizedImage
                  src={content.url}
                  srcset={content.srcset}
                  alt={content.alt}
                  format="medium"
                  cover
                />
              </span>
            );
          } else {
            return (
              <span
                key={`empty-${index}`}
                className="widget-theme__right__item"
              />
            );
          }
        })}
      </span>
    </span>
  );
};

export default withHideOnErrorBoundary(Theme);
