import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";

import classNames from "classnames";
import Icon from "theme/components/atoms/Icon";
import Modal from "theme/components/organisms/Modal";
import Thumbnail from "./Thumbnail";
const Mobile = ({ props }) => {
  const {
    productList,
    currentColorName,
    setCurrentColorName,
    currentProductColorName,
    previewContent,
    setPreviewContent,
    sku,
    displayModal,
    setDisplayModal,
    currentColorHexa,
    setCurrentColorHexa,
    displayType,
  } = props;
  return (
    <Fragment>
      <div
        className="form-item__label"
        onClick={() => {
          setDisplayModal(!displayModal);
        }}
      >
        <small>
          <FormattedMessage
            id="components.organism.SimilarColor.Title"
            defaultMessage="Color"
          />
        </small>

        <span className="similar-color__color-name">
          {currentColorHexa && (
            <span
              className="similar-color__color-color"
              style={{
                backgroundColor: `${currentColorHexa}`,
              }}
            />
          )}
          {currentColorName && currentColorName}
        </span>

        <span
          className={classNames("similar-color__color-count", {
            "similar-color__color-count--open": displayModal,
          })}
        >
          <FormattedMessage
            id="components.organism.SimilarColor.moreColor"
            defaultMessage="+ {colorCount} colors"
            values={{
              colorCount: productList.length,
            }}
          />
          <Icon icon="chevron" size="tiny" title="open" />
        </span>
      </div>
      <Modal
        isOpen={displayModal}
        onRequestClose={() => {
          setDisplayModal(!displayModal);
        }}
        contentLabel="Add to cart"
        extraClass="similar-color__modal"
      >
        <span className="modal__title">
          <FormattedMessage
            id="components.organism.SimilarColor.chooseColor"
            defaultMessage="Choose your color"
          />
        </span>
        <ul className="similar-color__modal__list">
          {productList.map((product, index) => {
            return (
              <li key={index}>
                <Thumbnail
                  currentProductSku={sku}
                  previewContent={previewContent}
                  setPreviewContent={setPreviewContent}
                  currentProductColorName={currentProductColorName}
                  setCurrentColorName={setCurrentColorName}
                  product={product}
                  displayType={displayType}
                  IsDesktop={true}
                  onClick={() => {
                    setCurrentColorHexa(product.collegien_color_hexa);
                    setCurrentColorName(product.collegien_color_name);
                    setDisplayModal(!displayModal);
                  }}
                />
              </li>
            );
          })}
        </ul>
      </Modal>
    </Fragment>
  );
};

export default Mobile;
