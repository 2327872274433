import React from "react";
import ProductItem from "theme/modules/ProductView/ProductItem";
import { withHideOnErrorBoundary } from "theme/components/helpers/ErrorBoundary";

const FourProduct = ({ loading, data }) => {
  if (loading) {
    return (
      <div className="four-products loading-skeleton">
        <div className="product-item" />
        <div className="product-item" />
        <div className="product-item" />
        <div className="product-item" />
      </div>
    );
  }

  if (!data) {
    return null;
  }
  const { products } = data;
  return (
    <div className="four-products">
      {products?.map((product, index) => {
        if (!product) {
          return false;
        }
        return (
          <ProductItem
            {...product}
            key={product.sku}
            products={products}
            product={product}
            index={index}
          />
        );
      })}
    </div>
  );
};

export default withHideOnErrorBoundary(FourProduct);
