import { makeAbsoluteUrl } from "theme/modules/Core";

export default (
  link,
  isCategoryLink = false,
  currentGenderLabel = "",
  genderFilterName = ""
) => {
  const url = link?.split("?");
  if (!url?.[0]) {
    return {
      pathname: "/",
    };
  }
  return {
    pathname: makeAbsoluteUrl(url[0]),
    search:
      isCategoryLink && currentGenderLabel
        ? `${genderFilterName}=${currentGenderLabel}${
            url[1] ? `&=${url[1]}` : ""
          }`
        : url[1] ?? null,
  };
};
