import React from "react";
import { injectIntl } from "react-intl";
import ProductItem from "theme/modules/ProductView/ProductItem";
import { H4 } from "theme/components/atoms/Typography/Heading";
import { RoundIconButton } from "theme/components/atoms/Button";
import { ButtonLink } from "theme/components/atoms/Typography/Link";
import Measure from "theme/utils/Measure";
import LabelledIcon from "theme/components/molecules/LabelledIcon";
import messages from "./messages";
import { makeAbsoluteUrl } from "theme/modules/Core";

const COLUMN_WIDTH = 98;
const GAP_WIDTH = 24;

const handleTranslate = (translateX, setTranslateX) => {
  const translateToRight = (gap = 0, allItems = 0) => {
    const newPosition = translateX - gap;

    if (allItems + newPosition < 0) {
      setTranslateX(-allItems);
    } else {
      setTranslateX(newPosition);
    }
  };

  const translateToLeft = (gap = 0) => {
    const newPosition = translateX + gap;
    if (newPosition > 0) {
      setTranslateX(0);
    } else {
      setTranslateX(newPosition);
    }
  };

  return [translateToRight, translateToLeft];
};

const displayTitle = (title) => {
  const titleSpaceSplit = title.split(/ /);
  return titleSpaceSplit.map((titleWord, i) => {
    if (parseInt(titleSpaceSplit.length / 2) === i) {
      return titleWord + " \n";
    }
    return titleWord + "";
  });
};

const ProductSliderDesktop = ({
  headingLevel,
  title,
  products,
  ctaLink,
  ctaLabel,
  intl,
}) => {
  const [translateX, setTranslateX] = React.useState(0);

  const [translateToRight, translateToLeft] = handleTranslate(
    translateX,
    setTranslateX
  );
  return (
    <div className="product-slider-desktop">
      <div className="product-slider-desktop__details">
        <div className="product-slider-desktop__details__title">
          <H4 as={headingLevel}>
            {title && typeof title === "string"
              ? displayTitle(title).join(" ")
              : title}
          </H4>
        </div>
        {ctaLabel && ctaLink && (
          <div className="product-slider-desktop__details__cta">
            <ButtonLink
              buttonAppearance="thirdly"
              to={makeAbsoluteUrl(ctaLink)}
            >
              <LabelledIcon icon="chevron-right" inverted size="tiny">
                {ctaLabel}
              </LabelledIcon>
            </ButtonLink>
          </div>
        )}
      </div>
      <Measure>
        {({ setRef: setRefRow, measure: measureRow }) => {
          return (
            <Measure>
              {({ setRef: setRefItem, measure: measureItem }) => {
                const allItems =
                  measureItem &&
                  measureRow &&
                  measureItem.width * products.length -
                    measureRow.width +
                    COLUMN_WIDTH +
                    GAP_WIDTH;

                return (
                  <div className="product-slider-desktop__content">
                    <div className="product-slider__prevnext__before">
                      <RoundIconButton
                        state={translateX >= 0 ? "disabled" : null}
                        appearance="primary"
                        onClick={() =>
                          translateToLeft(measureItem && measureItem.width)
                        }
                        onDisableClick={() => {}}
                        icon="chevron-left"
                        iconSize="tiny"
                        title={intl.formatMessage(messages.previous)}
                      />
                    </div>
                    <div
                      ref={setRefRow}
                      className="product-slider-desktop__content__items"
                      style={{ transform: `translate(${translateX}px)` }}
                    >
                      {products.map((product, index) => {
                        if (index === 0) {
                          return (
                            <span key={product.sku} ref={setRefItem}>
                              <ProductItem
                                {...product}
                                products={products}
                                product={product}
                                index={index}
                              />
                            </span>
                          );
                        }
                        return (
                          <span key={product.sku}>
                            <ProductItem
                              {...product}
                              products={products}
                              product={product}
                              index={index}
                            />
                          </span>
                        );
                      })}
                    </div>
                    <div className="product-slider__prevnext__after">
                      <RoundIconButton
                        state={translateX <= -allItems ? "disabled" : null}
                        appearance="primary"
                        onClick={() =>
                          translateToRight(
                            measureItem && measureItem.width,
                            allItems
                          )
                        }
                        icon="chevron-right"
                        iconSize="tiny"
                        title={intl.formatMessage(messages.next)}
                      />
                    </div>
                  </div>
                );
              }}
            </Measure>
          );
        }}
      </Measure>
    </div>
  );
};

export default injectIntl(ProductSliderDesktop);
