import React, { useState } from "react";
import Image from "./Image";
import { withHideOnErrorBoundary } from "theme/components/helpers/ErrorBoundary";

const Push = ({ loading, data }) => {
  const [hover, setHover] = useState(false);
  if (loading) {
    return (
      <span className={`push loading-skeleton push--full`}>
        <div className="push__image-container">
          <div className="push__image">
            <span className="push__image--image" />
            <div className="push__image__content">
              <span className="push__image__content--text" />
              <span className="link button button--ghost button--default">
                <div className="labelled-icon">
                  <div className="labelled-icon__content">
                    <span className="body" />
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </span>
    );
  }
  if (!data) {
    return null;
  }

  const {
    displayType,
    media,
    hoverMedia,
    ctaLabel,
    ctaLink,
    label,
    isCategoryLink,
  } = data;
  return (
    <span
      className={`push push--${displayType}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="push__image-container">
        <Image
          image={media}
          hover={hover}
          hoverImage={hoverMedia}
          display_type={displayType}
          isCategoryLink={isCategoryLink}
          ctaLabel={ctaLabel}
          label={label}
          ctaLink={ctaLink}
        />
      </div>
    </span>
  );
};

export default withHideOnErrorBoundary(Push);
