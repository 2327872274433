import React from "react";
import ProductItem from "theme/modules/ProductView/ProductItem";
import ResizedImage from "theme/components/atoms/ResizedImage";
import Swipe from "theme/components/organisms/Swipe";
import { RoundIconButton } from "theme/components/atoms/Button";
import { withHideOnErrorBoundary } from "theme/components/helpers/ErrorBoundary";

const Lookbook = ({ loading, data }) => {
  const [index, setIndex] = React.useState(1);

  if (loading) {
    return (
      <div className="widget-lookbook loading-skeleton">
        <div className="widget-lookbook__left" />
        <div className="widget-lookbook__right">
          <div className="widget-lookbook__right__item" />
          <div className="widget-lookbook__right__item" />
          <div className="widget-lookbook__right__item" />
          <div className="widget-lookbook__right__item" />
        </div>
      </div>
    );
  }

  if (!data) {
    return null;
  }
  const { mainMedia, title, bottomRightProduct, bottomRightMedia } = data;
  let mainMediaRender = (
    <div className="widget-lookbook__left">
      <ResizedImage
        src={mainMedia[0].url}
        srcset={mainMedia[0].srcset}
        alt={mainMedia[0].alt}
        format="themeFullImage"
        srcsetFormat={{ large: "lookbookWidgetDesktop" }}
        appearance="full"
        cover
      />
    </div>
  );
  if (mainMedia.length > 1) {
    const previous = mainMedia[index - 1] ?? null;
    const current = mainMedia[index];
    const next = mainMedia[index + 1] ?? null;

    mainMediaRender = (
      <div className="widget-lookbook__left">
        <RoundIconButton
          state={index <= 1 ? "disabled" : null}
          onDisableClick={() => {}}
          onClick={() => setIndex(index - 1)}
          icon="chevron"
          iconSize="tiny"
          title="before"
        />
        <Swipe
          current={
            <ResizedImage
              src={current.url}
              key={mainMedia[index]}
              srcset={current.srcset}
              alt={current.alt}
              format="themeFullImage"
              srcsetFormat={{ large: "lookbookWidgetDesktop" }}
              appearance="full"
              cover
            />
          }
          previous={
            previous && (
              <ResizedImage
                src={previous.media}
                key={mainMedia[index - 1]}
                srcset={previous.srcset}
                alt={previous.alt}
                srcsetFormat={{ large: "lookbookWidgetDesktop" }}
                format="themeFullImage"
                appearance="full"
                cover
              />
            )
          }
          next={
            next && (
              <ResizedImage
                src={next.media}
                key={mainMedia[index]}
                srcset={next.srcset}
                alt={next.alt}
                format="themeFullImage"
                srcsetFormat={{ large: "lookbookWidgetDesktop" }}
                appearance="full"
                cover
              />
            )
          }
          onNext={next ? () => setIndex(index + 1) : null}
          onPrevious={previous ? () => setIndex(index - 1) : null}
        />

        <RoundIconButton
          state={index + 1 >= mainMedia.length ? "disabled" : null}
          onDisableClick={() => {}}
          onClick={() => setIndex(index + 1)}
          icon="chevron"
          iconSize="tiny"
          title="next"
        />
      </div>
    );
  }

  return (
    <div className="widget-lookbook">
      {mainMediaRender}
      <div className="widget-lookbook__right">
        <h1 className="widget-lookbook__title widget-lookbook__right__item">
          {title}
        </h1>
        <span className="widget-lookbook__right__item"></span>
        {bottomRightProduct?.sku ? (
          <div
            className="widget-lookbook__right__item widget-lookbook__right__item--product"
            key={bottomRightProduct.sku}
          >
            <ProductItem
              {...bottomRightProduct}
              products={bottomRightProduct}
              product={[bottomRightProduct]}
            />
          </div>
        ) : null}
        {bottomRightMedia ? (
          <div
            key={bottomRightMedia.url}
            className="widget-lookbook__right__item widget-lookbook__right__item--media"
          >
            <ResizedImage
              src={bottomRightMedia.url}
              srcset={bottomRightMedia.srcset}
              alt={bottomRightMedia.alt}
              format="medium"
              cover
            />
          </div>
        ) : null}
        <div key={index} className="widget-lookbook__right__item" />;
      </div>
    </div>
  );
};

export default withHideOnErrorBoundary(Lookbook);
