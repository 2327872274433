import React from "react";
import ProductPush from "theme/components/organisms/Widget/ProductPush";
import ImageText from "theme/components/organisms/Widget/ImageText";
import TitleText from "theme/components/organisms/Widget/TitleText";
import ParallaxImage from "theme/components/organisms/Widget/ParallaxImage";
import ThreePush from "theme/components/organisms/Widget/ThreePush";
import FourEntry from "theme/components/organisms/Widget/FourEntry";
import ProductCarousel from "theme/components/organisms/Widget/ProductCarousel";
import Fullscreen from "theme/components/organisms/Widget/Fullscreen";
import ImageQuote from "theme/components/organisms/Widget/ImageQuote";
import Lookbook from "theme/components/organisms/Widget/Lookbook";
import Push from "theme/components/organisms/Widget/Push";
import Theme from "theme/components/organisms/Widget/Theme";
import FourProduct from "theme/components/organisms/Widget/FourProduct";
import loadable from "@loadable/component";

const defaultWidgetsMap = {
  "collegien_local/widget_productpush": loadable(
    () => import("theme/components/organisms/Widget/ProductPush"),
    {
      fallback: <ProductPush loading={true} />, // Skeleton
    }
  ),
  "collegien_local/widget_imagetext": loadable(
    () => import("theme/components/organisms/Widget/ImageText"),
    {
      fallback: <ImageText loading={true} />, // Skeleton
    }
  ),
  "collegien_local/widget_titletext": loadable(
    () => import("theme/components/organisms/Widget/TitleText"),
    {
      fallback: <TitleText loading={true} />, // Skeleton
    }
  ),
  "collegien_local/widget_parallaximages": loadable(
    () => import("theme/components/organisms/Widget/ParallaxImage"),
    {
      fallback: <ParallaxImage loading={true} />, // Skeleton
    }
  ),
  "collegien_local/widget_threeimages": loadable(
    () => import("theme/components/organisms/Widget/ThreePush"),
    {
      fallback: <ThreePush loading={true} />, // Skeleton
    }
  ),
  "collegien_local/widget_threepush": loadable(
    () => import("theme/components/organisms/Widget/ThreePush"),
    {
      fallback: <ThreePush loading={true} />, // Skeleton
    }
  ),
  "collegien_local/widget_fourentries": loadable(
    () => import("theme/components/organisms/Widget/FourEntry"),
    {
      fallback: <FourEntry loading={true} />, // Skeleton
    }
  ),
  "collegien_local/widget_productscarousel": loadable(
    () => import("theme/components/organisms/Widget/ProductCarousel"),
    {
      fallback: <ProductCarousel loading={true} />, // Skeleton
    }
  ),
  "collegien_local/widget_fullscreen": loadable(
    () => import("theme/components/organisms/Widget/Fullscreen"),
    {
      fallback: <Fullscreen loading={true} />, // Skeleton
    }
  ),
  "collegien_local/widget_imagequote": loadable(
    () => import("theme/components/organisms/Widget/ImageQuote"),
    {
      fallback: <ImageQuote loading={true} />, // Skeleton
    }
  ),
  "collegien_local/widget_lookbook": loadable(
    () => import("theme/components/organisms/Widget/Lookbook"),
    {
      fallback: <Lookbook loading={true} />, // Skeleton
    }
  ),
  "collegien_local/widget_push": loadable(
    () => import("theme/components/organisms/Widget/Push"),
    {
      fallback: <Push loading={true} />, // Skeleton
    }
  ),
  "collegien_local/widget_reassurance": loadable(
    () => import("theme/components/organisms/Reassurance"),
    {
      fallback: <></>, // Skeleton
    }
  ),
  "collegien_local/widget_ekomi": loadable(
    () => import("theme/components/organisms/Widget/Ekomi"),
    {
      fallback: <></>, // Skeleton
    }
  ),
  "collegien_local/widget_theme": loadable(
    () => import("theme/components/organisms/Widget/Theme"),
    {
      fallback: <Theme loading={true} />, // Skeleton
    }
  ),
  "collegien_local/widget_fourproducts": loadable(
    () => import("theme/components/organisms/Widget/FourProduct"),
    {
      fallback: <FourProduct loading={true} />, // Skeleton
    }
  ),
};

const RenderNothing = (props) => {
  if (process.env.NODE_ENV === "development") {
    console.warn(
      `The widget type ${JSON.stringify(
        props.type
      )} is not supported in MagentoWysiwyg. Please check "theme/modules/WysiwygV2/MagentoWysiwyg/Widget/getWidgetComponent.js" to see what's going on.`
    );
  }
  return null;
};

const getWidgetComponent = (customWidgetsMap) => {
  const widgetsMap = {
    ...defaultWidgetsMap,
    ...(customWidgetsMap || {}),
  };
  return (type) => {
    return widgetsMap[type] || RenderNothing;
  };
};

export default getWidgetComponent;
