import React from "react";
import generateLinkWidget from "theme/components/helpers/generateLinkWidget";
import Link from "theme/components/atoms/Typography/Link";
import ResizedImage from "theme/components/atoms/ResizedImage";
import classNames from "classnames";
import withGenderFilter from "theme/components/helpers/withGenderFilter";
import { withHideOnErrorBoundary } from "theme/components/helpers/ErrorBoundary";
import { compose } from "recompose";
const FourEntry = ({ loading, currentGenderLabel, genderFilterName, data }) => {
  if (loading) {
    return (
      <section className="four-entries skeleton-loading">
        <div className="four-entries__entry image">
          <span className="four-entries__entry__image" />
          <span className="four-entries__entry__title text--animate" />
        </div>
        <div className="four-entries__entry image">
          <span className="four-entries__entry__image" />
          <span className="four-entries__entry__title text--animate" />
        </div>
        <div className="four-entries__entry image">
          <span className="four-entries__entry__image" />
          <span className="four-entries__entry__title text--animate" />
        </div>
        <div className="four-entries__entry image">
          <span className="four-entries__entry__image" />
          <span className="four-entries__entry__title text--animate" />
        </div>
      </section>
    );
  }

  if (!data) {
    return null;
  }
  const { entries } = data;
  return (
    <section className="four-entries">
      {entries.map((entry, index) => (
        <div
          className={classNames("four-entries__entry ", {
            "four-entries__entry--with-shadow": entry.withShadow,
          })}
          key={index}
        >
          <Link
            to={generateLinkWidget(
              entry.link,
              entry.isCategoryLink,
              currentGenderLabel,
              genderFilterName
            )}
          >
            <div className="four-entries__entry__image">
              <ResizedImage
                src={entry.media.url}
                srcset={entry.media.srcset}
                alt={entry.media.alt}
                format={"fourEntries"}
                srcsetFormat={{
                  medium: "fourEntriesMobile",
                  small: "fourEntriesMobile",
                }}
                cover
              />
            </div>
            <span className="four-entries__entry__title">{entry.title}</span>
          </Link>
        </div>
      ))}
    </section>
  );
};

export default compose(withHideOnErrorBoundary, withGenderFilter())(FourEntry);
