import React from "react";
import { FormattedMessage } from "react-intl";

const Stickers = ({ stickers, isNew }) => (
  <div className="stickers">
    {isNew && (
      <div className="stickers__item stickers__item--new">
        <FormattedMessage
          id="components.organisms.Stickers.newLabel"
          defaultMessage="New in"
        />
      </div>
    )}
    {stickers &&
      stickers.length > 0 &&
      stickers.map((stickers, index) => (
        <div key={index} className="stickers__item">
          {stickers === "new_color" ? (
            <FormattedMessage
              id="components.organisms.Stickers.newColor"
              defaultMessage="New color"
            />
          ) : (
            stickers
          )}
        </div>
      ))}
  </div>
);

export default Stickers;
