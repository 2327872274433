import React from "react";

import Image from "./Image";
import Content from "./Content";
import Spinner from "theme/components/atoms/Spinner";
import { withHideOnErrorBoundary } from "theme/components/helpers/ErrorBoundary";

const ImageQuote = ({ loading, data }) => {
  if (loading) {
    return (
      <div className="imagequote">
        <Spinner />
      </div>
    );
  }
  if (!data) {
    return null;
  }
  const {
    imagePosition,
    media,
    text,
    subtitle,
    ctaLabel,
    ctaLink,
    headingLevel,
    title,
    fontSize,
    backgroundColor,
    imageFormat,
  } = data;
  if (imagePosition === "left") {
    return (
      <div
        className="imagequote"
        style={{ backgroundColor: backgroundColor ?? "transparent" }}
      >
        {media && (
          <Image
            image={media}
            ctaLink={ctaLink}
            format={imageFormat ?? "full"}
          />
        )}
        <Content
          withBackgroundColor={backgroundColor ? true : false}
          title={title}
          text={text}
          headingLevel={headingLevel}
          subtitle={subtitle}
          ctaLabel={ctaLabel}
          ctaLink={ctaLink}
          font_size={fontSize}
        />
      </div>
    );
  }
  return (
    <div
      className="imagequote"
      style={{ backgroundColor: backgroundColor ?? "transparent" }}
    >
      <Content
        withBackgroundColor={backgroundColor ? true : false}
        title={title}
        text={text}
        headingLevel={headingLevel}
        subtitle={subtitle}
        ctaLabel={ctaLabel}
        ctaLink={ctaLink}
        font_size={fontSize}
      />
      <Image image={media} ctaLink={ctaLink} format={imageFormat ?? "full"} />
    </div>
  );
};

export default withHideOnErrorBoundary(ImageQuote);
