import React from "react";
import Link from "theme/components/atoms/Typography/Link";
import LabelledIcon from "theme/components/molecules/LabelledIcon";
import ResizedImage from "theme/components/atoms/ResizedImage";
import generateLinkWidget from "theme/components/helpers/generateLinkWidget";

const Image = ({ image, genderFilterName, currentGenderLabel }) => {
  return (
    <div className="three-images__image">
      <Link
        to={generateLinkWidget(
          image.link,
          image.isCategoryLink,
          currentGenderLabel,
          genderFilterName
        )}
      >
        <ResizedImage
          src={image.image.url}
          srcset={image.image.srcset}
          format="threeImages"
          srcsetFormat={{
            large: "threeImages",
            medium: "threeImagesMobile",
            small: "threeImagesMobile",
          }}
          alt={image.image.alt}
          cover
        />

        <div className="three-images__image__content">
          <h3>{image.title}</h3>
          <span className="link button button--thirdly button--default">
            <LabelledIcon icon="chevron-right" inverted size="tiny">
              {image.labelCta}
            </LabelledIcon>
          </span>
        </div>
      </Link>
    </div>
  );
};

export default Image;
