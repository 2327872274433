import React from "react";
import EnhanceProductPush from "./EnhanceProductPush";
import ProductItem from "theme/modules/ProductView/ProductItem";
import ResizedImage from "theme/components/atoms/ResizedImage";
import generateLinkWidget from "theme/components/helpers/generateLinkWidget";
import Link from "theme/components/atoms/Typography/Link";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { ProductPrice } from "theme/components/atoms/Typography/Price";
const ProductPush = ({
  loading,
  hover,
  setHover,
  setHoverTitle,
  hoverTitle,
  data,
}) => {
  if (loading) {
    return <section className={`push-product loading-skeleton`}></section>;
  }
  if (!data) {
    return null;
  }
  const { product, image, title, link } = data;
  if (!product || !product.path) {
    return null;
  }

  return (
    <section
      className={classNames("push-product", {
        "push-product--hover": hover,
        "push-product--hover-title": hoverTitle,
        "push-product--with-media-hover": data.mediaHover === true,
      })}
    >
      <div
        className="push-product__item"
        onMouseEnter={() => setHover(data.mediaHover === true ? false : true)}
        onMouseLeave={() => setHover(data.mediaHover === true ? true : false)}
      >
        <ProductItem
          {...product}
          path={link ?? product.path}
          key={product.sku}
          products={[product]}
          product={{ ...product }}
          hover={true}
        />
        <Link to={link ?? generateLinkWidget(product.path)}>
          <ResizedImage
            src={image.url}
            srcset={image.srcset}
            alt={image.alt}
            format={"productPush"}
            srcsetFormat={{ small: "productPushMobile" }}
            cover
          />
        </Link>
      </div>
      <h3
        className="h5"
        onMouseEnter={() => setHoverTitle(true)}
        onMouseLeave={() => setHoverTitle(false)}
      >
        {title}
        <div
          className={classNames("", {
            "push-product__prices--hidden":
              !product.prices ||
              !product.prices.finalPrice?.priceInclTax?.value?.amount,
          })}
        >
          <div className="push-product__prices">
            <FormattedMessage
              id="modules.ProductView.ProductItem.Overview.pricePrefix"
              defaultMessage="From {price}"
              values={{ price: <ProductPrice prices={product.prices} /> }}
            />
          </div>
        </div>
      </h3>
    </section>
  );
};

export default EnhanceProductPush()(ProductPush);
