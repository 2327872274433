import React from "react";

import Link from "theme/components/atoms/Typography/Link";
import ResizedImage from "theme/components/atoms/ResizedImage";
import generateLinkWidget from "theme/components/helpers/generateLinkWidget";
import { withHideOnErrorBoundary } from "theme/components/helpers/ErrorBoundary";
const Fullscreen = ({ loading, data }) => {
  if (loading) {
    return (
      <div className="fullscreen skeleton-loading">
        <div className="image" />
      </div>
    );
  }
  if (!data) {
    return null;
  }
  const { link, media } = data;

  return (
    <section className="fullscreen">
      {link && link !== "/" ? (
        <Link buttonAppearance="ghost" to={generateLinkWidget(link)}>
          <ResizedImage
            src={media.url}
            srcset={media.srcset}
            alt={media.alt}
            cover
          />
        </Link>
      ) : (
        <ResizedImage
          src={media.url}
          srcset={media.srcset}
          alt={media.alt}
          cover
        />
      )}
    </section>
  );
};

export default withHideOnErrorBoundary(Fullscreen);
