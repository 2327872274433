import React from "react";
import IsDesktop from "theme/utils/isDesktop";
import ProductSliderMobile from "theme/components/organisms/ProductSlider/Mobile";
import ProductSliderDesktop from "theme/components/organisms/ProductSlider/Desktop";
import { withHideOnErrorBoundary } from "theme/components/helpers/ErrorBoundary";

const ProductCarousel = ({ data, loading }) => {
  if (loading) {
    return (
      <div className="product-slider-desktop loading-skeleton">
        <div className="product-slider-desktop__details">
          <div className="product-slider-desktop__details__title">
            <span className="product-slider-desktop__details__title--name" />
          </div>
        </div>
        <div className="product-slider-desktop__content">
          <div className="product-slider-desktop__content__items">
            <div className="product-list__item product-item">
              <span className="product-item__image" />
              <span className="product-item__text">
                <span className="product-item__title" />
                <span className="product-item__price" />
              </span>
            </div>
            <div className="product-list__item product-item">
              <span className="product-item__image" />
              <span className="product-item__text">
                <span className="product-item__title" />
                <span className="product-item__price" />
              </span>
            </div>
            <div className="product-list__item product-item">
              <span className="product-item__image" />
              <span className="product-item__text">
                <span className="product-item__title" />
                <span className="product-item__price" />
              </span>
            </div>
            <div className="product-list__item product-item">
              <span className="product-item__image" />
              <span className="product-item__text">
                <span className="product-item__title" />
                <span className="product-item__price" />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (!data) {
    return null;
  }
  const { title, ctaLink, ctaLabel, headingLevel = "h4", products } = data;

  if (!products || !products.length) {
    return null;
  }

  return (
    <IsDesktop>
      {(isDesktop) => {
        return isDesktop ? (
          <ProductSliderDesktop
            title={title}
            ctaLink={ctaLink}
            ctaLabel={ctaLabel}
            headingLevel={headingLevel}
            products={products}
          />
        ) : (
          <ProductSliderMobile
            title={title}
            ctaLink={ctaLink}
            ctaLabel={ctaLabel}
            headingLevel={headingLevel}
            products={products}
          />
        );
      }}
    </IsDesktop>
  );
};

export default withHideOnErrorBoundary(ProductCarousel);
