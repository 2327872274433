import React from "react";

import Image from "./Image";

const ImageText = ({ loading, data }) => {
  if (loading || !data) {
    return null;
  }
  const {
    image,
    text,
    imagePosition,
    backgroundColor,
    textColor,
    fontSize,
    isCategoryLink,
    link,
  } = data;

  const TextContent = () => (
    <span
      className="image-text__text-container"
      style={{
        backgroundColor,
        color: textColor,
        fontSize: fontSize || "4rem",
      }}
    >
      <span
        className="image-text__text"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </span>
  );

  const ImageContent = () => (
    <span className="image-text__image-container">
      <Image image={image} isCategoryLink={isCategoryLink} link={link} />
    </span>
  );

  return (
    <span className={`image-text ${imagePosition}`}>
      <TextContent />
      <ImageContent />
    </span>
  );
};

export default ImageText;
